@charset "utf-8";

// Web components (https://github.com/Thiememeulenhoff/frontend)
@import "~@thiememeulenhoff/themes/base.css";
// End web components

@import "theme-mixins";

@import "fonts";
@import "variables";
@import "mixins";
@import 'vue-animations';
@import 'loaders';

@import "~bulma/sass/utilities/_all.sass";
@import "~bulma/sass/base/_all.sass";

@import "type";
@import "icons";
@import "layout";

// Helpers
@import "helpers/interaction";
@import "helpers/_visibility.scss";
@import "helpers/_visibility.sass";
//
// Content elements (markup created in XSLT)
//
@import "content/sections";
@import "content/link";
@import "content/inline-image";
@import "content/conversation";
@import "content/poetry";
@import "content/mail";
@import "content/asset";
@import "content/quote";
@import "content/table";
@import "content/list";
@import "content/tip";
@import "components/spacing";

@import "components/svg-icon";
@import "components/inline-svg";
@import "components/flexbox";
@import "components/navbar";
@import "components/button";
@import "components/labeled-title";
@import "components/resource-title";
@import "components/modal";
@import "components/modal--card";
@import "components/breadcrumb";
@import "components/related-links";
@import "components/tooltip";
@import "components/full-screen-background";
@import "components/tabs";
@import "components/offsetted-anchor";
@import "components/popover";
@import "components/media-modal";
@import "components/table";
@import "components/loader";
@import "components/notification";
@import "components/notification-toaster";
@import "components/form";

@import "components/dropdown";
@import "components/notifications";
@import "components/centered-container";
@import "components/context-menu";
@import "components/share-button";
@import "components/search";

@import "pages/error-page";
@import "pages/about-page";

@import "admin/admin";

@import "print";

* {
  box-sizing: border-box;
}
