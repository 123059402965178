$admin-base-background-color: #6994ba;
$admin-error-background-color: $tm-poor-dark;
$admin-info-color: $tm-good-heavy;

.button--admin {
  @include button-color(
    $background-color: $admin-base-background-color,
    $hover-background-color: darken($admin-base-background-color, 7.5%),
    $text-color: $white
  );
}

.admin-info {
  font-weight: bold;
  color: $admin-info-color;
}

.button--admin-fix,
.button--admin-delete {
  position: relative;
  @include button-color(
    $background-color: $white,
    $hover-background-color: darken($white, 7.5%),
    $text-color: $admin-error-background-color
  );
  font-size: 0.875rem;
  font-family: $family-sans-serif;
  &.button--loading {
    pointer-events: none;
    @include loading-bar($height: 3px, $color: $admin-error-background-color);
  }
  &.button--disabled {
    pointer-events: none;
    @include button-color(
      $background-color: $white,
      $hover-background-color: darken($white, 7.5%),
      $text-color: $admin-error-background-color
    );
    opacity: 0.5;
  }
}

.button--admin-fix-sequence {
  position: relative;
  @include button-color(
    $background-color: $admin-error-background-color,
    $hover-background-color: darken($admin-error-background-color, 7.5%),
    $text-color: $white
  );
  font-size: 0.875rem;
  font-family: $family-sans-serif;
  &.button--loading {
    pointer-events: none;
    @include loading-bar($height: 3px, $color: $white);
  }
  &.button--disabled {
    pointer-events: none;
    @include button-color(
      $background-color: $admin-error-background-color,
      $hover-background-color: darken($admin-error-background-color, 7.5%),
      $text-color: $white
    );
    opacity: 0.5;
  }
}


.admin-user-results {
  .admin-user-results__button-only-errors {
    display: inline-block;
  }
  .admin-user-results__button-all {
    display: none;
  }
  &.is-collapsed {
    .admin-user-results__button-only-errors {
      display: none;
    }
    .admin-user-results__button-all {
      display: inline-block;
    }
    .admin-user-results__without-repairs {
      display: none;
    }
    .table--user-results {
      tr {
        display: none;
        &.table--user-results-header,
        &.missing_db_result,
        &.missing_es_result,
        &.incorrect_es_state {
          display: table-row;
        }
      }
    }
  }

  &--with-data-container {
    display: flex;
    flex-direction: row;
    width: 100%;
  }
  &--with-data-container-row {
    flex: 1
  }
  &__data-container {
    width: 100%;
    min-height: 50vh;
    border: $table-cell-border;
    padding: $table-cell-padding;
  }
  div.data-buttons {
    display: flex;
    flex-direction: row;
  }
}

.table--user-results {
  &-condensed {
    font-size: 0.8rem;
  }
  .missing_db_result,
  .missing_es_result,
  .incorrect_es_state {
    background-color: $admin-error-background-color;
    color: $white;
  }
  .no-border-cell {
    border: 0 none;
  }
  .is-collapsed {
    display: none;
  }
}

i.icon-view.js-collapse {
  cursor: pointer;
}

.title-with-button {
  display: inline-flex;
  width: 100%;
  justify-content: space-between;
}

a-accordion {
  &::part(summary) {
    background: 0 none;
    padding: 0 0.75rem;
    &:hover {
      background: 0 none;
    }
  }
  &::part(main) {
    padding-top: 0;
  }
}

ul.dotted {
  list-style: disc;
  padding-left: 2rem;

  li {
    white-space: nowrap;
  }
}

ul.learninggoals {
  li {
    max-width: 30vw;
    min-width: 10rem;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.title-comment {
  font-style: italic;
}

li.learninggoal {

}
